<template>
  <div class="bg-1">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12">

          <v-overlay v-if="isLoading" opacity="0.8" color="white">
            <v-progress-circular color="gifty" size="50" indeterminate></v-progress-circular>
          </v-overlay>

          <div v-else>
            <v-row align="center" justify="center">
              <v-col cols="12" lg="11">
                <div class="my-5 text-center">

                  <img alt="" src="@/assets/logo2.png"/>

                  <div class="d-flex align-center font-weight-bold justify-center align-content-center mt-5"
                       :style="{fontSize:'30px'}">
                    Soirées Ramadan 100% DZ – Musique, Humour & Détente !
                  </div>

                  <p class="grey--text text--darken-2 mt-2">
                    Vivez des soirées inoubliables au Sun Club de Sidi Fredj, du 3 au 27 mars :<br> concerts, spectacles
                    humoristiques, projections et moments cosy autour d’un thé.
                  </p>

                  <h3 class="gifty-text">
                    Achetez votre ticket en deux clicks !
                  </h3>

                  <h4>
                    1 . Sélectionnez votre soirée
                  </h4>

                  <h4>
                    2 . Recevez votre ticket par SMS
                  </h4>

                  <h3 class="mt-3">
                    <span class="gifty-text"> Places limitées !</span> Sélectionnez votre soirée et réservez maintenant.
                  </h3>

                </div>

                <v-row class="pb-15">
                  <v-col cols="6"
                         lg="3"
                         class="d-flex flex-column"
                         v-for="(item,i) in services"
                         :key="i">
                    <v-card outlined class="rounded-lg flex d-flex flex-column">
                      <v-card-text class="flex">
                        <v-img class="rounded-lg grey lighten-4"
                               min-height="180"
                               :src="getImageLink(item.background_image)"></v-img>

                        <p class="text-center font-weight-medium mt-3 f-14 grey--text text--darken-3">
                          {{ item.name }}
                        </p>

                        <div class="text-center">
                          <v-btn color="gifty" depressed dark rounded
                                 @click="[form.amount = item.vouchers && item.vouchers.length ? item.vouchers[0].amount : 0 ,service = item , dialog =true]">
                            <v-icon left>mdi-cart-plus</v-icon>
                            Acheter
                          </v-btn>
                        </div>

                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>

            <p class="text-center py-5">
              &copy; {{ new Date().getFullYear() }} GIFTY dz. Tous droits réservés.
            </p>

          </div>

        </v-col>
      </v-row>

      <v-dialog v-model="dialog"
                scrollable
                width="1000"
                persistent
                :fullscreen="$vuetify.breakpoint.mobile">
        <v-card>
          <div class="d-flex align-center pa-6 f-17 font-weight-medium justify-space-between">
            {{ service.name }}
            <v-icon color="red" @click="dialog = false">mdi-close</v-icon>
          </div>
          <v-divider></v-divider>
          <v-card-text class="pa-8 pa-lg-8">

            <v-row>
              <v-col cols="12" lg="6">
                <v-img v-if="service && service.gallery"
                       class="rounded-lg grey lighten-4"
                       :src="getImageLink(service.gallery[0])"></v-img>
                <v-row class="mt-3" align="center">
                  <v-col cols="5" lg="3">
                    <v-img class="rounded-lg grey lighten-4"
                           :src="getImageLink(service.background_image)"></v-img>
                  </v-col>
                  <v-col cols="7" lg="9">
                    <h4 class="mb-2  grey--text text--darken-3">{{ service.name }}</h4>
                    <p v-html="service.short_description" class="f-15"></p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="6">
                <div class="table-border rounded-lg">

                  <h4 class="pa-4  grey--text text--darken-3">
                    Détails du paiement
                  </h4>

                  <v-divider/>

                  <div class="pa-4">
                    <v-radio-group v-model="form.amount"
                                   :error-messages="errors.amount"
                                   v-if="service.vouchers && service.vouchers.length  > 1"
                                   label="Veuillez choisir un montant *"
                                   mandatory>
                      <v-radio v-for="(voucher,vIndex) in service.vouchers"
                               :key="vIndex"
                               color="gifty"
                               :value="voucher.amount"
                               :label="voucher.amount"></v-radio>
                    </v-radio-group>

                    <v-alert type="warning" text color="info" icon="mdi-alert-outline">
                      Attention : Veuillez saisir votre numéro de téléphone valide pour recevoir le lien de vote
                      e-ticket.
                    </v-alert>

                    <v-text-field outlined
                                  :error-messages="errors.phone"
                                  color="gifty"
                                  hide-details="auto"
                                  v-model="form.phone"
                                  label="Votre numéro de téléphone *"
                                  v-number
                                  counter="10"
                                  type="tel"
                                  placeholder="0XX XX XX XX"
                                  required
                    ></v-text-field>

                    <v-card flat color="grey lighten-4" class="mb-3 rounded">
                      <v-card-text>
                        <div class="d-flex align-center justify-space-between">
                          Sous-total
                          <span>{{ CurrencyFormatting(form.amount) }} DZD</span>
                        </div>
                        <v-divider class="my-3"/>
                        <h3 class="d-flex align-center justify-space-between font-weight-bold gifty-text">
                          Total net
                          <span>{{ CurrencyFormatting(form.amount) }} DZD</span>
                        </h3>
                      </v-card-text>
                    </v-card>

                    <v-checkbox v-model="form.accept" color="gifty">
                      <template v-slot:label>
                        <div>
                          J'accepte les
                          <a target="_blank"
                             href="/cgu-eticket"
                             @click.stop
                          >
                            Conditions Générales d'Utilisation
                          </a>
                        </div>
                      </template>
                    </v-checkbox>

                    <v-btn @click="confirm"
                           :loading="btnLoading"
                           color="success"
                           rounded
                           depressed
                           large
                           block
                           :disabled="!form.accept || !form.phone || form.phone.length !== 10"
                    >
                      <v-icon left>mdi-check</v-icon>
                      Confirmer
                    </v-btn>


                  </div>
                </div>
              </v-col>
            </v-row>

            <v-card flat outlined class="mt-3">
              <v-tabs v-model="tab"
                      background-color="transparent"
                      centered
                      color="gifty"
                      icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                  Description
                  <v-icon>mdi-file-document</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  Utilisation
                  <v-icon>mdi-cog</v-icon>
                </v-tab>

              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item :value="'tab-1'">
                  <v-card flat>
                    <v-card-text>
                      <div class="pa-4" v-html="service.description"></div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-2'">
                  <v-card flat>
                    <v-card-text>
                      <div class="pa-4" v-html="service.usage"></div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>

            </v-card>


          </v-card-text>
        </v-card>
      </v-dialog>

    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      tab: null,
      isLoading: false,
      btnLoading: false,
      dialog: false,
      services: [],
      service: {},
      form: {
        phone: null,
        phone_1: null,
        amount: 0,
        accept: false,
      },
      errors: {}
    };
  },
  methods: {
    getServices() {
      this.isLoading = true
      axios.get(process.env.VUE_APP_BASE_URL + "100dz-services").then(res => {
        this.services = res.data.data.filter(service => ![138, 180, 178].includes(service.id));
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    confirm() {
      this.btnLoading = true;

      // Replace leading 0 with +213
      if (this.form.phone && this.form.phone.startsWith('0')) {
        this.form.phone_1 = '+213' + this.form.phone.slice(1);
      }

      axios.post(process.env.VUE_APP_BASE_URL + "100dz-store-epayment", {
        number: this.form.phone_1,
        amount: this.form.amount,
        service_id: this.service.id,
      }).then((res) => {
        this.btnLoading = false;
        let redirectUrl = res.data.data.checkout_url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      }).catch(err => {
        this.btnLoading = false;
        this.errors = err.response.data.errors;
        console.log(err);
      });
    }
  },
  created() {
    this.getServices();
  },
};
</script>

<style scoped>
.bg-1 {
  background: #FFFFFF;
  min-height: 100vh;
}
</style>